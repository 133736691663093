import React from "react";
import FlashText from "./FlashText"
import {Text} from "react-native";
import { useTranslation } from "react-i18next";
import i18n from "../translations/i18n";

const Lancement = () => {
  const { t, i18n } = useTranslation();
  return (
      <FlashText text = {t("Lancement")} />
  );
};
export default Lancement;
