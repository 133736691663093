import { Text } from 'react-native';
import React, { Component } from 'react';
import Icon3 from "react-native-vector-icons/dist/Entypo";
import "../icons/icons.js";
import "../icons/styleicons.css";

export default class FlashText extends Component {
  constructor(props) {
    super(props);
    this.state = { showText: true };

    // Change the state every second or the time given by User.
    setInterval(() => {
        this.setState(previousState => {
          return { showText: !previousState.showText };
        });
      },
      // Define blinking time in milliseconds
      1000
    );

  }

  render() {
    let display = this.state.showText ? this.props.text : ' ';
    return (
      <Text style={{ direction : localStorage.getItem('keyLng') === "ar" ? "rtl": "ltr", fontSize: 30, alignItems:"center", justifyContent:"center", textAlign: 'center', marginTop: 10, opacity: this.state.showText ? 0 : 1 }}> <Icon3 name="back-in-time" style={{color: "black", fontSize:'xx-large', marginRight: 10}} /> {this.props.text}</Text>
    );
  }
}
