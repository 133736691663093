const en = {
  translation: {
    //cycle Menu
    "Accueil": "Home",
    "Explorer":"Explore",
    "Télécharger":"Downloads",
    "Tarifs":"Pricing",
    "Accès Skolaria": "Start Skolaria",
    "Essai 40 jours":"40 days for free",
    "Tous droits réservés":"All rights reserved",
    //fin cycle Menu

    "Skobot_L1":"Hello, my name’s SkoBot!",
    "Skobot_L2":"I could answer and manage anything you desire. Currently, humans are still assembling me, I will be ready soon :) For now, you can write to us right here :",
    "Close":"Close",
    "fullname":"Full Name",
    "email":"Email",
    "yrmsg":"Your Message",
    "send":"Send",
    "thank_ticket":"Thank you, please check your mailbox within 24 hours or less, an agent will answer your ticket",
    "Banner_Title":"Simplifying Schooling.",
    "Banner_Dev":"Skolaria develops for educational sector the most complete services ensuring the best eficient experience.",
    "Learn more":"Explore",
    "Lancement":"Upcoming launch",
    "rot":"Rotate your phone",


  }
};

export default en;
