import React from "react";
import {View, StyleSheet, Pressable, Text} from "react-native";

import { withTranslation } from 'react-i18next';
import i18n from "../translations/i18n";
import i18nn from "../translations/i18nLocalDevice";

import Icon from "react-native-vector-icons/dist/SimpleLineIcons";
import "../icons/icons.js";
import "../icons/styleicons.css";
import "./stylecss/styles.css";
import { Consumer } from "./Context"

class Bar_Lang extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      // ici on a pensé à établir 4 states pour les 4 langues, on pourra utiliser un seul state seulement dans le cas d'un componenet tabs, mais ici, c'est un component personnalisé

      //on doit utiliser un tranchant de string et prendre les deux premières lettres pour éviter le probleme entre laptop fr-FR et mobile fr
      bt_fr : i18n.language.substring(0, 2) == "fr"  ? true : false,
      bt_es : i18n.language.substring(0, 2)  == "es" ? true : false,
      bt_en : i18n.language.substring(0, 2)  == "en" ? true : false,
      bt_ar : i18n.language.substring(0, 2)  == "ar" ? true : false,


    }
  }

  componentWillUnmount() {

  }

  async componentDidMount() {
    await Font.loadAsync({
      'goldBP': require('../fonts/GoldleafBoldPersonal.ttf'),
      'heiReina': require('../fonts/HeiReinaRegular.ttf'),
      'montserrat': require('../fonts/MontserratThin.ttf'),
      'montserratbold': require('../fonts/MontserratSemibold.ttf')
    });


  }
  suite_en = () => {
    i18n.changeLanguage("en");
    this.setState({bt_en : true , bt_fr: false , bt_es :false, bt_ar: false});
    localStorage.setItem('keyLng',"en")
  }
  suite_fr = () => {
    i18n.changeLanguage("fr");
    this.setState({bt_fr : true , bt_en: false , bt_es :false, bt_ar: false});
    localStorage.setItem('keyLng',"fr")
  }
  suite_ar = () => {
    i18n.changeLanguage("ar");
    this.setState({bt_ar : true , bt_fr: false , bt_es :false, bt_en: false});
    localStorage.setItem('keyLng',"ar")
  }
  suite_es = () => {
    i18n.changeLanguage("es");
    this.setState({bt_es : true , bt_fr: false , bt_en :false, bt_ar: false});
    localStorage.setItem('keyLng',"es")
  }
  render(){
    const { t } = this.props;
    //const [context, setContext] = useContext(Context);
    //const { t, i18n } = useTranslation();
    const styles = StyleSheet.create({
      lng_ico_img: {
        flex: 1,//2
        // opacity:0.8,
        height:'2vh',
        //backgroundColor: "#075f8d",
        borderRadius : 10,
        textAlign: "center",
        justifyContent:"center",
        borderColor: "#d1d1d1",
        borderWidth: 1,
        marginRight:5,
        padding:5,
      },
      lng_ico_txt: {
        fontFamily:'montserratbold',// 'montserratbold',
        flex: 1,//2
        fontSize: '2.5vw',
        textAlignVertical: "center",
        //color: "#075f8d"
      },

      vieww:{
        //backgroundColor:'red',
        flexDirection: "row",
        justifyContent: 'space-evenly',
        alignItems:'center',
        margin: 10,
        position: 'fixed',
        width:'47%',
        left:'1%',
        height:'1vh',
        bottom: '10vh',
      },
      icoon:{
        justifyContent: 'center',
        alignItems:'center',
        color: "white",
        fontSize: 18,
        paddingLeft: 1,
        marginRight:5,
      },

    });



    return(
      // Pass on our props

      <Consumer>
      {context => (

        <View style={styles.vieww}>

        <Icon name="globe-alt" style={styles.icoon}/>

        <Pressable
        style={[styles.lng_ico_img,{backgroundColor: this.state.bt_en ? "#d1d1d1" : "#075f8d"}]}
        onPress={() => { this.suite_en(); context.initDirection();}}
        >

        <Text style={[styles.lng_ico_txt,{color: this.state.bt_en ?
          "#075f8d" : "#d1d1d1"}]}>En</Text>
          </Pressable>


          <Pressable
          style={[styles.lng_ico_img,{backgroundColor: this.state.bt_fr ? "#d1d1d1" : "#075f8d"}]}
          onPress={() => { this.suite_fr(); context.initDirection();}}>

          <Text style={[styles.lng_ico_txt,{color: this.state.bt_fr ?
            "#075f8d" : "#d1d1d1"}]}>Fr</Text>
            </Pressable>

            <Pressable
            style={[styles.lng_ico_img,{backgroundColor: this.state.bt_es ? "#d1d1d1" : "#075f8d"}]}
            onPress={() => { this.suite_es(); context.initDirection();}}>

            <Text style={[styles.lng_ico_txt,{color: this.state.bt_es ?
              "#075f8d" : "#d1d1d1"}]}>Es</Text>
              </Pressable>

              <Pressable
              style={[styles.lng_ico_img,{backgroundColor: this.state.bt_ar ? "#d1d1d1" : "#075f8d"}]}
              onPress={() => { this.suite_ar(); context.changeDirection();}}
              >
              <Text style={[styles.lng_ico_txt,{color: this.state.bt_ar ? "#075f8d" : "#d1d1d1"}]}>ع</Text>
              </Pressable>
              </View>
            )}
            </Consumer>


          );
        }
      }
      export default withTranslation()(Bar_Lang)
