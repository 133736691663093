//Selon le site formsubmit, ceci fonctionne avec un attribut action, mais pour eviter la redirection à une nouvelle page de merci, on a changer les atrributs de form et utiliser fetch (voir la documentation et favoris forms stackoverflox)

// <input type="hidden" name="_cc" value="another@email.com"/> pourra être ajouté ultérieurement pour envoyer une copie à une autre boite
//cas plusieurs emails: <input type="hidden" name="_cc" value="another@email.com,yetanother@email.com"/>


import React from 'react';
import {Text} from "react-native";

import { withTranslation } from 'react-i18next';
import i18n from "../translations/i18n";
import "../Composants/stylecss/styles.css";
import Icon from "react-native-vector-icons/dist/FontAwesome";

import "../icons/icons.js";
import "../icons/styleicons.css";



class MyForm extends React.Component{
  state = {
    subj: "",
    mail: "",
    result:"",
    dehactivate:true,
  };
  constructor(props){
    super(props);
    //si c'est function, ça s'écrit autrement, voir dossier correspondant
    this.form = React.createRef();

  }

  handleSubmit = (e) => {
    this.setState({dehactivate: false});
    e.preventDefault();
    const data = new FormData(this.form.current);

    fetch('https://formsubmit.co/ajax/skolaria.project@gmail.com', {
      method: 'POST',
      body: data,
    })
    .then((response) => response.json())
    .then((data) => console.log(data))
    .catch((error) => console.log(error));
  };
  handleChangeName = () => {
    this.setState({subj: event.target.value});
    this.setState({result: "New Ticket from " + event.target.value + ' (' + this.state.mail + ')'});
  };
  handleChangeMail = () => {
    this.setState({mail: event.target.value});
    this.setState({result: "New Ticket from " + this.state.subj + ' (' + event.target.value + ')'});
  };


  componentWillUnmount() {
  }

  async componentDidMount() {
  }
  render(){
    const { t } = this.props;
    return(



      this.state.dehactivate ?

      <div
      class= {localStorage.getItem('keyLng') === "ar" ? "container_Ar": "container" }>
      <form ref={this.form} method="POST" encType="multipart/form-data" onSubmit={this.handleSubmit}
      >
      <input type="hidden" name="_template" value="table"/>
      <input type="hidden" name="_subject" value = {this.state.result} />

      <div class= {localStorage.getItem('keyLng') === "ar" ? "form-group_Ar": "form-group" } >
      <div class="form-row">
      <div class="col">
      <input
      type="text"
      name="name"
      class= {localStorage.getItem('keyLng') === "ar" ? "form-control_Ar": "form-control" }
      placeholder={t("fullname")}
      onChange={this.handleChangeName}

      required/>
      </div>
      <div class="col">
      <input
      type="email"
      name="email"
      class= {localStorage.getItem('keyLng') === "ar" ? "form-control_Ar": "form-control" }
      placeholder={t("email")}
      onChange={this.handleChangeMail}
      required/>
      </div>
      </div>
      </div>
      <div  class= {localStorage.getItem('keyLng') === "ar" ? "form-group_Ar": "form-group" }>

      <textarea
      placeholder={t("yrmsg")}
      class= {localStorage.getItem('keyLng') === "ar" ? "form-control_Ar": "form-control" }
      name="message"
      rows="4"
      required></textarea>
      </div>
      <button type="submit" class="btn btn-lg btn-dark btn-block">{t("send")}</button>
      </form>
      </div>

      :

      <h4 class= {localStorage.getItem('keyLng') === "ar" ? "D_rtl": "D_ltr" }>
      <Icon
      name="check-circle"
      style={{
        justifyContent: 'center',
        alignItems:'center',
        color: "#07BC35",
        fontSize: 17,
        paddingLeft: 1,
        marginRight:5,
        marginLeft:5,
      }}/>
      {t("thank_ticket")}
      </h4>


    );
  }
}
export default withTranslation()(MyForm)
