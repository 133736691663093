const fr = {
  translation: {
    //cycle Menu
    "Accueil": "Accueil",
    "Explorer":"Explorer",
    "Télécharger":"Télécharger",
    "Tarifs":"Tarifs",
    "Accès Skolaria": "Accès Skolaria",
    "Essai 40 jours":"Essai 40 jours",
    "Tous droits réservés":"Tous droits réservés",
    //fin cycle Menu

    "Skobot_L1":"Bonjour, je m'appelle SkoBot!",
    "Skobot_L2":"Je saurais répondre et gérer tous vos soucis. Pour le moment, des humains sont encore en train de m'assembler, je serais prêt pour bientôt :) En attendant, n'hésitez pas à nous contacter par ici :",
    "Close":"Fermer",
    "fullname":"Nom Complet",
    "email":"Email",
    "yrmsg":"Votre Message",
    "send":"Envoyer",
    "thank_ticket":"Merci de vérifier votre boite mail dans moins de 24 heures, un agent répondra à votre ticket",
    "Banner_Title":"Simplifiant l'enseignement.",
    "Banner_Dev":"Skolaria développe les services les plus performants dédiés aux établissements scolaires afin de garantir une expérience éducationnelle rentable et simple.",
    "Learn more":"Explorer",
    "Lancement":"Lancement prochain",
    "rot":"Pivotez votre téléphone",
  }
};

export default fr;
