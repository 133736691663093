
// <View style={styles.app}>
//
//     <Text> {Dimensions.get ('window').width} {Dimensions.get ('window').height} </Text>
// </View>

//Explication: après avoir pensé à un menu pour le mobile, on a rencontré la librairie react burger menu qui utilise les div et l'ancien format css... Donc ou pourra marier entre jsx et ceci comme le montre le code ci dessous... Attention la syntaxe entre stylesheet de jsx et css varie légérement

// l'ascenseur ne fonctionnera qu'avec une div en overflow (auto, scroll)et height: 100vh; pour la balise app

// le modal est chargé une seule fois dans un screen et pas dans tous les screens, et l'ouverture et la fermeture du modal se fera grace au state context


import React from "react";
import Header from '../Composants/Header.js'

import { Dimensions } from 'react-native';
import {Alert, View, Text, Pressable, TouchableOpacity, KeyboardAvoidingView} from "react-native";

import MenuMobile from "../Composants/MenuMobile";
import Fab_Support from "../Composants/Fab_Support";
import Fab_Access from "../Composants/Fab_Access";
import Lancement from "../Composants/Lancement";
import { Consumer } from "../Composants/Context"


import "../Composants/stylecss/styles.css";

import Modal from "modal-enhanced-react-native-web";
import StyleSheet from 'react-native-media-query';



import { withTranslation } from 'react-i18next';
import i18n from "../translations/i18n";




// const wiw = Dimensions.get('screen').width; //donne la dimension de l'écran et pas de la fenetre (on change à window)
const wih = Dimensions.get('screen').height;
// const scw =  Dimensions.get('window').width;

import Icon from "react-native-vector-icons/dist/MaterialCommunityIcons";
import Icon2 from "react-native-vector-icons/dist/Ionicons";


import "../icons/icons.js";
import "../icons/styleicons.css";


import MyForm from "../Composants/MyForm";
import LinearGradient from "react-native-web-linear-gradient";

//import Test from '../images/svg/Test.svg'; // import SVG
import Logov8 from '../images/svg/logov8.js';
// let widthh = Dimensions.get ('window').width;
// let heightt = Dimensions.get ('window').height;

const window2 = Dimensions.get("window");
const screen2 = Dimensions.get("screen");

class Acc extends React.Component{


  constructor(props){
    super(props);

    const isPortrait = () => {
      const dim = Dimensions.get('screen');
      return dim.height >= dim.width;
    };

    this.state = {
      dimensions: {
        window2,
        screen2
      },
      previousOrient: isPortrait() ? 'portrait' : 'landscape',
      decisionReload: false,
      decisionShow: false,
      orientation: isPortrait() ? 'portrait' : 'landscape',
      previousHeight: Dimensions.get('window').height,
      newHeight: Dimensions.get('window').height
    };

    // Event Listener for orientation changes
    Dimensions.addEventListener('change', () => {

      this.setState({
        orientation: isPortrait() ? 'portrait' : 'landscape',
        newHeight: Dimensions.get('window').height
      });
      {((this.state.orientation === this.state.previousOrient) &&
        (this.state.newHeight != this.state.previousHeight))?
        this.setState({
          decisionReload: true,
        })
        :
        this.setState({
          decisionReload: false,
        });
      }
      {((this.state.orientation === this.state.previousOrient) &&
        (this.state.newHeight < this.state.previousHeight))?
        this.setState({
          decisionShow: true
        })
        :
        this.setState({
          decisionShow: false
        });
      }


      //if true => Hard reload (skiping cache info)
      {this.state.decisionReload === true ? null:window.location.reload(false);}


      this.setState({
        previousOrient: this.state.orientation,
        previousHeight: this.state.newHeight
      });

    });
  }
  onChange = ({ window2, screen2 }) => {
    {this.state.decisionReload === true ? null :   this.setState({ dimensions: { window2, screen2 }});}

  };
  componentWillUnmount() {
      Dimensions.removeEventListener("change", this.onChange);
  }

  async componentDidMount() {
    await Font.loadAsync({
      'goldBP': require('../fonts/GoldleafBoldPersonal.ttf'),
      'heiReina': require('../fonts/HeiReinaRegular.ttf'),
      'montserrat': require('../fonts/MontserratThin.ttf'),
      'montserratbold': require('../fonts/MontserratSemibold.ttf')
    });
    Dimensions.addEventListener("change", this.onChange);

  }

  render(){
    const { dimensions } = this.state;
    const { t } = this.props;
    const {ids, styles} = StyleSheet.create({
      view:{
        //height: sch * (90/100),
        height: '100vh',
        flex:1,
        flexDirection:'column',
        overflow: 'auto',
        //height: '92vh', //7% pour le header et chwya pour la bordure vide
      },
      view2:{
        //height: sch * (90/100),
        height: '100vh',
        overflow: 'auto',
        flex:1,
        alignItems: "center",
        justifyContent: "center",
        //height: '92vh', //7% pour le header et chwya pour la bordure vide
      },
      button: {
        position: 'absolute', right: '0%', top:'0%',

        padding: 7,
        margin: 16,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 10,
        borderColor: "rgba(0, 0, 0, 0.4)"
      },
      modalContent: {
        width: '50%',
        left: '25%',
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        padding: 22,
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 25,
        borderColor: "rgba(0, 0, 0, 0.5)",


        '@media (max-width: 1200px)': {
          width: '100%',
          left: '0%',
        },
      },
      icoon:{
        position: 'absolute', left: '5%', top:'6%',
        color: "#0071e3",
        fontSize: 50,
        paddingLeft: 1,


        '@media (max-width: 1200px)': {
          fontSize: 40,
          top:'4%',
        },

      },
      icoon2:{

        color: "#0071e3",
        fontSize: 30,
        paddingLeft: 1,
      },
      icoon3:{

        color: "black",
        fontSize: 100,
        justifyContent: "center",
        alignItems: "center",

        //paddingLeft: 1,
      },
      texto:{
        fontSize:18,

        '@media (max-width: 1200px)': {
          fontSize: 16,
        },
      },
      texto2:{
        fontSize:18,
        paddingTop: 20,

        '@media (max-width: 1200px)': {
          fontSize: 16,
          paddingTop: 20
        },
      },
      banner_home:{
        flex:3,
        alignItems:"center",
        justifyContent:"center",
        padding: 30,


        '@media (max-width: 1200px)': {

          flexDirection : "column",
          padding: 10,
          flex:5,
        },

      },
      wrapped_svg:{



        '@media (max-width: 1200px)': {
          flex:4,
          width:'100%'
        },
      },
      svg_v8:{
        flex:2,
        padding: 40,


        '@media (max-width: 1200px)': {
          padding: 5,
          flex:1

        },
      },
      gradi:{
        flexDirection : "row",
        height: wih * (60/100),


        '@media (max-width: 1200px)': {
          display:'none',
        },
      },
      gradi_mob:{
        flexDirection : "column",
        height: wih * (55/100),
        '@media (max-width: 10700px) and (min-width: 1201px)': {
          display:'none',
        },

        '@media (max-width: 1200px)': {

        },
      },
      banner_title_text:{
        flex:1,
        fontFamily: 'montserrat',
        textAlignVertical: "center",
        textAlign: "center",
        color: 'white',
        fontSize: wih * (3/60),
        fontWeight: 'bold',


        '@media (max-width: 1200px) and (min-width: 411px)' : {
          flex:1,
          fontSize: wih * (3/90),
          padding: 10,
        },
        '@media (max-width: 411px)' : {
          flex:1,
          fontSize: wih * (3/110),
          padding: 10,
        },

      },
      banner_dev_text:{
        flex:2,
        textAlignVertical: "center",
        textAlign: "justify",
        color: 'black',
        fontSize: wih * (3/95),
        padding: wih * (3/140),
        fontFamily: 'montserratbold',


        '@media (max-width: 1200px)': {
          flex:2,
          fontSize: wih * (3/140),
          padding: wih * (3/140),

        },
      },
      banner_btn:{
        direction : localStorage.getItem('keyLng') === "ar" ? "rtl": "ltr",
        flexDirection : "row",
        flex:1,
        width: '60%',
        alignItems:"center",
        justifyContent:"space-evenly",


        '@media (max-width: 1200px)': {
          flex:1,
          width: '88%',
          left: '6%',
        },


      },
      btn_acc:{
        flex: 3,
        alignItems: 'center',
        justifyContent: 'center',
        height: wih * (4/100),


        backgroundColor: '#8F3CF2',
        //marginTop: wih * (2/100),
        borderRadius: 30,
        padding: wih * (1/100),
        //padding: Platform.OS === 'web' ? '1vh' : '1%',//10,
        //marginTop: Platform.OS === 'web' ? '2vh' : '2%',//heightt/44,


        '@media (max-width: 1200px)': {
          flex: 2,
          //margin:wih * (1/200),

        },
      },
      btn_free:{
        flex: 2,
        alignItems: 'center',
        justifyContent: 'center',
        height: wih * (3/100),


        //height: Platform.OS === 'web' ? '5vh' : '5%',//heightt/50,
        //margin:wiw * (1/200),
        //marginTop: Platform.OS === 'web' ? '2vh' : '2%',//heightt/44,
        marginRight: wih * (1/100),
        marginLeft: wih * (1/100),
        borderRadius: 30,
        padding: wih * (1/100),
        //padding: Platform.OS === 'web' ? '1vh' : '1%',//10,
        borderWidth: 2,
        borderColor: '#8F3CF2',

        '@media (max-width: 1200px)': {

          //height:0,
          //margin: 0,
          //marginLeft:wih * (1/200),
          //marginRight: wih * (1/200),
        },

      },
      text_btn_acc:{
        fontFamily: 'montserratbold',
        color: 'white',
        //fontSize: wiw * (1/100),
        fontSize: wih * (3/150),
        //fontSize: Platform.OS === 'web' ? '2vh' : '2%',//widthh/72,
        //lineHeight:  Platform.OS === 'web' ? '2vh' : '2%',//heightt/60,
        //fontWeight: 'bold',
        //letterSpacing: 0.25,
        //fontWeight: "bold",


        '@media (max-width: 1200px)': {
          fontSize: wih * (3/190),
        },
      },
      text_btn_free:{
        fontFamily: 'montserrat',
        color: '#8F3CF2',
        fontSize: wih * (3/150),
        //fontSize: wiw * (1/95),
        //fontSize: Platform.OS === 'web' ? '2vh' : '2%',//widthh/100,
        //lineHeight:  Platform.OS === 'web' ? '2vh' : '2%',//heightt/60,
        fontWeight: "bold",



        '@media (max-width: 1200px)': {
        fontSize: wih * (3/190),
        },
      },
      lancement:{
        width: '100%',
        marginTop: 30,
        alignItems:"center",
        justifyContent:"center",
      }
    });

    if ((this.state.orientation === 'portrait') || ((this.state.orientation === 'landscape') && (Dimensions.get('screen').height > 500))) {

      return (
        <Consumer>
        {context => (
          <KeyboardAvoidingView behavior="padding">
          <div id="App">
          <Header stylo= {this.props.navigation} whichi= "Acc"/>
          <MenuMobile  pageWrapId={"page-wrap"} outerContainerId={"App"} />
          <Fab_Support/>
          <Fab_Access/>


          {context.state.modalDejaChargee === 1 || context.state.modalDejaChargee === 0 ?
            <Modal
            isVisible={context.state.opensupport === 1}
            // onBackdropPress={() => context.closeSup()}
            >
            <View style={styles.modalContent}  dataSet={{media: ids.modalContent}}>
            <Icon name="robot-happy"
            style={styles.icoon}
            dataSet={{media: ids.icoon}}
            />

            <Text
            style={styles.texto}
            dataSet={{media: ids.texto}}
            >{t("Skobot_L1")}</Text>

            <Text
            style={styles.texto2}
            dataSet={{media: ids.texto2}}
            >{"\n"}{t("Skobot_L2")}</Text>
            <MyForm/>

            <TouchableOpacity
            style={styles.button}
            onPress={() => context.closeSup()}
            >
            <View >
            <Icon2
            name="close-circle"
            style={styles.icoon2}
            />

            </View>
            </TouchableOpacity>

            </View>
            </Modal>
            : null }



            <div id="page-wrap">
            <View style={styles.view}>

            <LinearGradient
            colors={['#8F3CF2', '#3284D7', '#65ABF1', '#9FCBF7', '#DEEDFB']}
            style={styles.gradi}
            dataSet={{media: ids.gradi}}
            >
            <View style={styles.banner_home}>
              <Text numberOfLines={1} selectable = {false} style={styles.banner_title_text} dataSet={{ media: ids.banner_title_text}}>{t("Banner_Title")}</Text>
              <Text selectable = {false} style={styles.banner_dev_text} dataSet={{ media: ids.banner_dev_text}}>{t("Banner_Dev")}</Text>

              <View style={styles.banner_btn} dataSet={{ media: ids.banner_btn}}>
                <Pressable style={styles.btn_acc} dataSet={{ media: ids.btn_acc}} onPress={() => this.props.navigation.navigate('Pri')} >
                  <Text numberOfLines={1} selectable = {false} style={styles.text_btn_acc} dataSet={{ media: ids.text_btn_acc}}
                  >
                  {t("Essai 40 jours")}</Text>
                </Pressable>

                <Pressable style={styles.btn_free} dataSet={{ media: ids.btn_free}}
                onPress={() => this.props.navigation.navigate('Exp')}
                >
                  <Text selectable = {false} style={styles.text_btn_free} dataSet={{ media: ids.text_btn_free}}

                  >  {t("Learn more")}</Text>
                </Pressable>
              </View>

            </View>

            <Logov8 style ={styles.svg_v8} dataSet={{ media: ids.svg_v8}}/>

            </LinearGradient>



            <LinearGradient
            colors={['#8F3CF2', '#3284D7', '#65ABF1', '#9FCBF7', '#DEEDFB']}
            style={styles.gradi_mob}
            dataSet={{media: ids.gradi_mob}}
            >
            <Text numberOfLines={1} selectable = {false} style={styles.banner_title_text} dataSet={{ media: ids.banner_title_text}}>{t("Banner_Title")}</Text>

            <View style={styles.banner_home} dataSet={{ media: ids.banner_home}}>
              <View style={styles.wrapped_svg} dataSet={{ media:ids.wrapped_svg}}>
                <Logov8 style ={styles.svg_v8} dataSet={{ media: ids.svg_v8}}/>
              </View>
              <Text selectable = {false} style={styles.banner_dev_text} dataSet={{ media: ids.banner_dev_text}}>{t("Banner_Dev")}</Text>
            </View>

            <View style={styles.banner_btn} dataSet={{ media: ids.banner_btn}}>
              <Pressable style={styles.btn_acc} dataSet={{ media: ids.btn_acc}} onPress={() => this.props.navigation.navigate('Pri')} >
                <Text numberOfLines={1} selectable = {false} style={styles.text_btn_acc} dataSet={{ media: ids.text_btn_acc}}
                >
                {t("Essai 40 jours")}</Text>
              </Pressable>

              <Pressable style={styles.btn_free} dataSet={{ media: ids.btn_free}}
              onPress={() => this.props.navigation.navigate('Exp')}
              >
                <Text  selectable = {false} style={styles.text_btn_free} dataSet={{ media: ids.text_btn_free}}

                >  {t("Learn more")}</Text>
              </Pressable>
            </View>
            </LinearGradient>

              <View style={styles.lancement}>
                <Lancement/>
              </View>
            </View>
            </div>
            </div>
            </KeyboardAvoidingView>
          )}
          </Consumer>
       );

    }
    else {
      return (
        <div id="App">
          <View style={styles.view2}>
            <Text>{t("rot")}</Text>
            <Icon name="phone-rotate-portrait"
              style={styles.icoon3}
              />
          </View>
        </div>

      );
    }
    }
  }
  export default withTranslation()(Acc)
