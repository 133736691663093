const es = {
  translation: {
    //  cycle Menu
    "Accueil": "Portada",
    "Explorer":"Explorar",
    "Télécharger":"Descargar",
    "Tarifs":"Tarifas",
    "Accès Skolaria": "Ir a Skolaria",
    "Essai 40 jours":"Probar 40 días",
    "Tous droits réservés":"Todos los derechos reservados",
    //fin cycle Menu

    //debut cycle Acc

    "Skobot_L1": "Hola, me llamo SkoBot!",
    "Skobot_L2":"Podría responder y gestionar cualquier cosa que desee. Actualmente, los humanos todavía me están ensamblando, estaré listo pronto :) Por ahora, puede escribirnos aqui mismo :",
    "Close":"Cerrar",
    "fullname":"Apellido y Nombre",
    "email":"Correo Electrónico",
    "yrmsg":"Vuestro Mensaje",
    "send":"Enviar",
    "thank_ticket":"Gracias! Recibimos vuestro mensaje y en menos de 24 horas, recibiría una respuesta",
    "Banner_Title":"Simplificando la escolarización.",
    "Banner_Dev":"Skolaria desarrolla los servicios más eficientes dedicados a la escolarización para garantizar una experiencia educativa rentable y sencilla.",
    "Learn more":"Explorar",
    "Lancement":"Lanzamiento pronto",
    "rot":"Gira tu teléfono",

    //fin cycle Acc
  }
};

export default es;
