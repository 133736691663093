import * as React from 'react';
import { View, Button } from 'react-native';
// import * as Analytics from 'expo-firebase-analytics';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import { withTranslation } from 'react-i18next';
import i18n from "./translations/i18n";

import { Provider } from "./Composants/Context";




// ./ signifie que le document cherché se trouve au même niveau du répertoire de ce document app
// ../ signifie que le document cherché se trouve au niveau parent du répertoire de ce document app
import Acc from "./screens/Acc.js";
import Downloads from "./screens/Downloads.js";
import Explore from "./screens/Explore.js";
import Pricing from "./screens/Pricing.js";

// ceci pour les fonctions, on utilise useTranslation, mais pour les classes on utilise with translation: voir le détail sur Discarded 5
class App extends React.Component{
  state = {
    lngar: false,
    openMenu: false,
    opensupport: 0,
    modalDejaChargee: 0,
  }
  modalChargee = newName => {
    this.setState({
      modalDejaChargee: newName,
    })
  }
  //cette fonction context correction permet de corriger l'anomalie de click sur le bouton hamburger qui se voit alterner un état inacceptable
  correction = newName => {
    this.setState({
      openMenu: newName,
    })
  }
  openSup = () => {
    this.setState({
      opensupport: this.state.opensupport === 1? 0 : 1,
    })
  }
  closeSup = () => {
    this.setState({
      opensupport: 0,
    })
  }
  openMenu_a = () => {
    this.setState({
      openMenu: !this.state.openMenu,
    })
  }


  changeDirection = () => {
    this.setState({
      lngar: !this.state.lngar,
    })
  }

  initDirection = () => {
    this.setState({
      lngar: false,
    })
  }

  constructor(props){
    super(props);

  }
  componentWillUnmount() {

  }
  async componentDidMount() {

  }
  render(){
    const MyTheme = {
      dark: false,
      colors: {
        primary: 'rgb(255, 45, 85)',
        background: 'rgb(255, 255, 255)',
        card: 'rgb(255, 255, 255)',
        text: 'rgb(28, 28, 30)',
        border: 'rgb(199, 199, 204)',
        notification: 'rgb(255, 69, 58)',
      },
    };
    const { t } = this.props;
    const Stack = createStackNavigator();
    const linking = {
      config: {
        screens: {
          Hom: '',
          Exp: 'explore',
          Dow: "downloads",
          Pri:"pricing",
        }
      }
    };

    return(




      <Provider value={{
        state: this.state,
        changeDirection: this.changeDirection,
        initDirection: this.initDirection,
        openMenu_a: this.openMenu_a,
        openSup: this.openSup,
        closeSup: this.closeSup,
        correction: e => this.correction(e),
        modalChargee: e => this.modalChargee(e),
      }}>
      <NavigationContainer

      linking={linking}
      theme={MyTheme}
      >
      <Stack.Navigator screenOptions={{headerShown: false}}>
      <Stack.Screen name="Hom" component={Acc} options={{ title: t("Accueil") + ' - Skolaria'}} />
      <Stack.Screen name="Exp" component={Explore} options={{ title: t("Explorer") + ' - Skolaria'}} />
      <Stack.Screen name="Dow" component={Downloads} options={{ title: t("Télécharger") + ' - Skolaria' }}/>
      <Stack.Screen name="Pri" component={Pricing} options={{ title: t("Tarifs") + ' - Skolaria' }} />
      </Stack.Navigator>
      </NavigationContainer>
      </Provider>

    );

  }
}
export default withTranslation()(App)
