import React from "react";
import { FAB } from '@rneui/themed';
import { Consumer } from "./Context"
import Icon from "react-native-vector-icons/dist/MaterialCommunityIcons";

import "../icons/icons.js";
import "../icons/styleicons.css";
import StyleSheet from 'react-native-media-query';

import { useTranslation } from "react-i18next";
import i18n from "../translations/i18n";
import {Linking} from 'react-native';


const Fab_Access = () => {
  const { t, i18n } = useTranslation();
  const {ids, styles} = StyleSheet.create({

    styleFab:{

      zIndex: 55555,
      marginRight: 20,
      marginBottom: 26,

      '@media (max-width: 107400px) and (min-width: 1201px)': {
          display :'none',
      },
      '@media (max-width: 1200px)': {

        display:'inline-block',
      },
    }

   });

  return (

    <Consumer>
    {context => (
      <FAB
      visible= {!context.state.openMenu}
      icon={<Icon name="exit-to-app" style={{color: "white", fontSize:'large'}} />}
      size="small"
      style={styles.styleFab}
      dataSet={{media: ids.styleFab}}
      color="#0071e3"
      title=  {t("Accès Skolaria")}
      placement = 'left'
      onPress={() => {Linking.openURL('https://app.skolaria.com')}}
      />

    )}
    </Consumer>

  );
};
export default Fab_Access;
