// ce fichier ne comportait pas consumer et import consumer avant l'utilisation du fab, ne pas se tromper ici

import React from "react";
import { push as Menu } from "react-burger-menu"; //scaleRotate pushRotate
import "../icons/icons.js";
import "../icons/styleicons.css";
import SeparatorMM from "./SeparatorMM.js";

import Bar_Lang from "./Bar_Lang.js";

import Icon from "react-native-vector-icons/dist/FontAwesome";
import Icon2 from "react-native-vector-icons/dist/Feather";
import Icon3 from "react-native-vector-icons/dist/Ionicons";

//import { createDrawerNavigator } from '@react-navigation/drawer';


import {StyleSheet, View, Text, TouchableOpacity} from "react-native";

import { withTranslation } from 'react-i18next';
import i18n from "../translations/i18n";
import { Consumer } from "./Context"


import "./stylecss/styles.css";
import Burger from 'react-css-burger';


let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

class MenuMobile extends React.Component{
  state = {
    menuOpen: false,
  };
  handleStateChange (state) {
    this.setState({menuOpen: state.isOpen});
  }
  toggleMenu () {
    this.setState(state => ({menuOpen: !state.menuOpen}));
  }


  constructor(props){
    super(props);


  }

  componentWillUnmount() {

  }

  async componentDidMount() {




  }
  render(){
    const { t } = this.props;
    //const { t, i18n } = useTranslation();
    const styles = StyleSheet.create({
      icoon:{
        color: "white",
        fontSize: 20,
        paddingLeft: 1,
        marginRight:10,
      },
      button: {
        backgroundColor: "#61e3a5",
        padding: 10,
        borderRadius: 10,
        margin: 10
      }


    });



    return(
      // Pass on our props... this.props qui est dans le menu permet de faire focntionner les animations avec pagewrap et container
      <Consumer>
      {context => (
        <Menu {...this.props}

          customBurgerIcon ={ <Burger
          onClick={() => { this.toggleMenu(); context.openMenu_a(); context.correction(!this.state.menuOpen);}}

          active={context.state.openMenu}
          burger = "spring"
          color="#075f8d"
          hoverOpacity={0.7}
           scale={1}
          />}
          disableCloseOnEsc
          disableOverlayClick
          isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)}
          width={ '50%' }

          customCrossIcon ={
            <Icon3.Button
            active={!context.state.openMenu}
            name="ios-return-up-back"
            backgroundColor={'transparent'}
            size={35}
            color={'#bdc3c7'}
            onPress={() => { this.toggleMenu(); context.openMenu_a();context.correction(!this.state.menuOpen);}}
            />
          }>



          <a className="menu-item" href="/">
          <Icon name="home" style={styles.icoon} />{t("Accueil")}</a>
          <SeparatorMM/>


          <a className="menu-item" href="/explore">
          <Icon name="search" style={styles.icoon} />{t("Explorer")}</a>
          <SeparatorMM/>


          <a className="menu-item" href="/downloads">
          <Icon2 name="download" style={styles.icoon}/>{t("Télécharger")}</a>
          <SeparatorMM/>

          <a className="menu-item" href="/pricing">
          <Icon3 name="pricetags" style={styles.icoon} />{t("Tarifs")}</a>
          <SeparatorMM/>

          <Bar_Lang />






          <Text style={{position: 'fixed', flex:1, width:'50%', left:0,
          bottom: '5vh', fontSize: '2.5vw', color : "#d1d1d1", textAlign:"center",
          alignItems:"center", justifyContent:"center"}} >
          Copyright © {new Date().getFullYear()}
          </Text>
          <Text
          style={
            {flex:1, width:'50%',left:0,position:'fixed',bottom: '2vh',
            fontSize: '2.5vw',color : "#d1d1d1",textAlign : "center",
            alignItems: "center",justifyContent:"center"}}>
            Skolaria - {t("Tous droits réservés")}
            </Text>


            </Menu>

          )}
          </Consumer>
        );
      }
    }
    export default withTranslation()(MenuMobile)
