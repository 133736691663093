// ce fichier pourra largement nécessiter des changements de code en cas de mise à jour

//import i18n from "i18next";
//import { initReactI18next } from "react-i18next";
import * as Localization from 'expo-localization';
import i18nn from 'i18n-js';

i18nn.locale = Localization.locale; //or 'es'//Localization.locale;
//i18nn.fallbacks = true;


export default i18nn;
