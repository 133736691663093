import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en";
import es from "./es";
import ar from "./ar";
import fr from "./fr";
import i18nn from "./i18nLocalDevice"



i18n.use(initReactI18next).init({
  lng: localStorage.getItem('keyLng') || i18nn.locale,  // i18nn.locale,
  //ici on a utilisé localstorage pour si jamais le site n'est pas chargé pour la prmière fois, sinon il chargera i18nn.locale pour la première fois
  //local storage est une propriété de window et peut être communiqué entre plusieurs componenets
  // pour le moment, on a pas utiliser les context pour passer la langue pour deux raisons: si le site se reload: on perd le paramètre passé entre les componenets, la deuxième: la structure de i18n (même pas une fonction) ne permet pas d'utiliser les contexts et ça ne marchera pas même si une classe à cause de la première raison
  fallbackLng: "en",
  resources: {
    en: en,
    es: es,
    fr: fr,
    ar: ar
    //attentio à ne pas mettre la virgule à la fin (après ar), spécialement dans ce fichier, sinn ça marche pas
  }
});
export default i18n;
