const ar = {
  translation: {
    //cycle Menu
    "Accueil": "الرئيسية",
    "Explorer":"تعرف علينا",
    "Télécharger":"تحميل",
    "Tarifs":"التعريفة",
    "Accès Skolaria": "دخول المنصة",
    "Essai 40 jours":"جرب 40 يوما بالمجان",
    "Tous droits réservés":"جميع الحقوق محفوظة",
    //fin cycle Menu

    "Skobot_L1":"مرحبًا ، اسمي SkoBot!",
    "Skobot_L2":"سيمكنني الرد وإدارة أي شيء تريده وذلك بعدما ينتهي فريق من تجميعي، سأكون جاهزًا عما قريب :) إلى حين ذلك ، أرسل لنا جميع تساؤلاتك من هنا :",
    "Close":"إغلاق",
    "fullname":"الإسم الكامل",
    "email":"البريد الإلكتروني",
    "yrmsg":"رسالتكم هنا",
    "send":"إرسال",
    "thank_ticket":"شكرا، لقد توصلنا بتساؤلكم، سنعمل على الرد عليكم في أقل من 24 ساعة، المرجو مراجعة بريدكم الإلكتروني لاحقا",
    "Banner_Title":"من أجل تمدرس مبسط.",
   "Banner_Dev":"نقوم بتطوير أكثر الخدمات كفاءة بالعالم لفائدة قطاع التعليم لضمان تجربة تعليمية فعالة وسهلة الاستعمال.",
    "Learn more":"اكتشف المزيد",
    "Lancement":"الانطلاقة قريبا",
    "rot":"قم بتدوير الهاتف",


  }
};

export default ar;
