
//on a du laisser plattform.os à cause de la taille du fenetre qui serre les objets on réduisant la taille.
//on a a revenu a dimensions pour avoir un rendu comme apple et drive.com, la taille ne change plus
/////////ATTTTTTENTION: les stylescheet doivent être vérifiés à la fin du développement par la variable wiw et non pas la variable scw
import StyleSheet from 'react-native-media-query';
import React from "react";
import {Dimensions, Platform, Image, Text, View, Pressable} from "react-native";
import * as Font from 'expo-font';
import {Linking} from 'react-native';

//import { useTranslation } from "react-i18next"; // valable pour une fonction , mais pour une classe, on doit modifier quelques choses (voir discarded, projet 5)
// étapes: 1 on ajoute withtranslation.
//2 on supprime export default sur la ligne de la classe et on l'ajoute différement dans la dernière ligne
//3 on ajout la ligne des props

import { withTranslation } from 'react-i18next';
import i18n from "../translations/i18n";

//import {TestContext} from "./Bar_Lang";

import { Consumer } from "./Context"

import { Button } from '@rneui/base';
import Logov6 from '../images/svg/logov6.js';

import Burger from 'react-css-burger';

const wiw = Dimensions.get('screen').width; //donne la dimension de l'écran et pas de la fenetre (on change à window)
const wih = Dimensions.get('screen').height;
const scw =  Dimensions.get('window').width;
//const { t, i18n } = useTranslation();

class Header extends React.Component{
  //step6 Context (step7 here)
  //  static envoi = TestContext;

  constructor(props){
    super(props);
  }

  componentWillUnmount() {

  }

  async componentDidMount() {
    await Font.loadAsync({
      'goldBP': require('../fonts/GoldleafBoldPersonal.ttf'),
      'heiReina': require('../fonts/HeiReinaRegular.ttf'),
      'montserrat': require('../fonts/MontserratThin.ttf'),
      'montserratbold': require('../fonts/MontserratSemibold.ttf')
    });


  }


  render(){

    const { t } = this.props;
    const {ids, styles} = StyleSheet.create({

      exampleeeee: {
        //code pour >=1075
        backgroundColor: 'green',
        '@media (max-width: 1074px) and (min-width: 767px)': {

        },

        '@media (max-width: 766px)': {

        },
      },
      header: {
        flexDirection : "row",
        height: wih * (7/100),
        width: "auto",
        '@media (max-width: 1200px)': {
          direction:'ltr',
            height: wih * (5/100),
        },
      },
      logo_img: {
        flex: 2,//2
        //height:Platform.OS === 'web' ? '8vh' : '8%',
        //height:wih * (6/100),
        width: '80%',
        padding: 5,
        //backgroundColor: 'red'
      },
      logo_txt: {
        flex: 3,//3
        fontFamily: 'heiReina',
        fontSize: wih * (10/150),
        marginTop: wih * (1/200),


        '@media (max-width: 1200px)': {
          //backgroundColor: "yellow",
          flex: 7,
          fontSize: wih * (10/170),
          marginTop: - wih * (1/400),
          alignItems:"center",
          justifyContent:"center",
          textAlignVertical: "center",
          textAlign: "center",
        },
      },
      menuPr:{
        flex:15,
        //direction: i18n.locale == "ar" ? 'rtl' : 'ltr',
        '@media (max-width: 1200px)': {
          display: 'none',
          direction:'ltr',
        },

      },
      menuButt:{
        flexDirection : "row",
        flex:4,

      },
      menuAnim:{
        flexDirection : "row",
        flex:1,
        justifyContent: 'space-around',

      },
      btn_menu:{
        flex:1,
        //backgroundColor: 'red',
        alignItems: 'center',
        justifyContent: 'center',
        //selectable:"false"
      },

      text_btn_menu_off:{
        fontFamily: 'goldBP',
        fontSize: wih * (9/300),
        color: 'black',
        textAlign: "center",


        '@media (max-width: 1200px)': {

        },
      },
      text_btn_menu_on:{
        //fontSize: wiw * (2/105),
        fontSize: wih * (10/300),
        color: '#055DB4',
        fontFamily: 'goldBP',
        textAlign: "center",



        '@media (max-width: 1200px)': {

        },
      },
      btn_menu_anim_on:{
        backgroundColor: '#0071e3',
        width: wih * (1/8),
        height: "40%",
        borderRadius: 95,

        '@media (max-width: 1200px)': {
        },
      },
      btn_menu_anim_off:{
        height:0,
        width: wih * (10/80),


        '@media (max-width: 1200px)': {

        },
      },
      menuButtAcc:{
        flex:7,
        alignItems:"center",
        justifyContent:"space-evenly",
        flexDirection : "row",


        '@media (max-width: 1200px)': {
          flex:2,//2
        },
      },
      btn_free:{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        height: wih * (3/100),
        marginRight: wih * (1/100),
        marginLeft: wih * (1/100),
        borderRadius: 30,
        padding: wih * (1/100),
        borderWidth: 2,
        borderColor: '#0071e3',

        '@media (max-width: 1200px)': {
          flex:0,
          display: 'none',
        },

      },
      btn_acc:{
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        height: wih * (4/100),
        backgroundColor: '#0071e3',
        borderRadius: 30,
        padding: wih * (1/100),

        '@media (max-width: 1200px)': {
          flex: 0,
          display: 'none',
        },
      },

      text_btn_acc:{
        fontFamily: 'montserratbold',
        color: 'white',
        fontSize: wih * (3/165),

        '@media (max-width: 1200px)': {
          fontSize: 0,
        },
      },
      text_btn_free:{
        fontFamily: 'montserrat',
        color: '#0040FF',
        fontSize: wih * (3/165),
        fontWeight: "bold",


        '@media (max-width: 1200px)': {
          fontSize: 0,
        },
      },


      vide_bordure:{
        marginTop: wih * (1/370),
        width:"auto",
        borderColor:'gainsboro',
        borderBottomWidth:1
      },
    });


  //on utilisera la bilbio media query... le style ET/OU le media seront utilisés aux componenets qu'on voudrait


    return(
      <Consumer>
      {context => (


        <View style={styles.app}>
        <View
        style={[styles.header, {direction: localStorage.getItem('keyLng') === "ar" ? 'rtl': 'ltr'}  || {direction: context.state.lngar ?  'rtl' : 'ltr'}]}
        dataSet={{media: ids.header}}>



        <Logov6 style ={styles.logo_img}/>

        <Text numberOfLines={1} selectable = {false} style={styles.logo_txt} dataSet={{media: ids.logo_txt}}>
        Skolaria</Text>
        <View
        style={[styles.menuPr, {direction: localStorage.getItem('keyLng') === "ar" ? 'rtl': 'ltr'}  || {direction: context.state.lngar ?  'rtl' : 'ltr'} ]}
        dataSet={{ media: ids.menuPr}}>
        <View style={styles.menuButt}>
        <Pressable
        style={styles.btn_menu}
        onPress={(e) =>{
          context.modalChargee(1);
          this.props.stylo.navigate('Hom');
        }} >
        <Text
        numberOfLines={1}
        selectable = {false}
        style={this.props.whichi === "Acc" ? styles.text_btn_menu_on:styles.text_btn_menu_off}
        dataSet={{media: ids.text_btn_menu_on},{media: ids.text_btn_menu_off}}>

        {t("Accueil")}</Text>
        </Pressable>

        <Pressable style={styles.btn_menu}
        onPress={(e) =>{
          context.modalChargee(2);
          this.props.stylo.navigate('Exp');
        }} >
        <Text
        numberOfLines={1}
        selectable = {false}
        style={this.props.whichi === "Exp" ? styles.text_btn_menu_on:styles.text_btn_menu_off}
        dataSet={{media: ids.text_btn_menu_on},{media: ids.text_btn_menu_off}}>
        {t("Explorer")}</Text>
        </Pressable>

        <Pressable style={styles.btn_menu}
        onPress={(e) =>{
          context.modalChargee(3);
          this.props.stylo.navigate('Dow');
        }} >
        <Text
        numberOfLines={1}
        selectable = {false}
        style={this.props.whichi === "Dow" ? styles.text_btn_menu_on:styles.text_btn_menu_off}
        dataSet={{media: ids.text_btn_menu_on},{media: ids.text_btn_menu_off}}>
        {t("Télécharger")}</Text>
        </Pressable>

        <Pressable style={styles.btn_menu}
        onPress={(e) =>{
          context.modalChargee(4);
          this.props.stylo.navigate('Pri');
        }} >
        <Text
        numberOfLines={1}
        selectable = {false}
        style={this.props.whichi === "Pri" ? styles.text_btn_menu_on:styles.text_btn_menu_off}
        dataSet={{media: ids.text_btn_menu_on},{media: ids.text_btn_menu_off}}>
        {t("Tarifs")}</Text>
        </Pressable>

        </View>
        <View style={styles.menuAnim}>
        <Pressable
        style= {this.props.whichi === "Acc" ? styles.btn_menu_anim_on:styles.btn_menu_anim_off}
        dataSet={{media: ids.btn_menu_anim_on},{media: ids.btn_menu_anim_off}}>
        </Pressable>

        <Pressable

        style= {this.props.whichi === "Exp" ? styles.btn_menu_anim_on:styles.btn_menu_anim_off}
        dataSet={{media: ids.btn_menu_anim_on},{media: ids.btn_menu_anim_off}}>
        </Pressable>

        <Pressable
        style= {this.props.whichi === "Dow" ? styles.btn_menu_anim_on:styles.btn_menu_anim_off}
        dataSet={{media: ids.btn_menu_anim_on},{media: ids.btn_menu_anim_off}}>
        </Pressable>

        <Pressable
        style= {this.props.whichi === "Pri" ? styles.btn_menu_anim_on:styles.btn_menu_anim_off}
        dataSet={{media: ids.btn_menu_anim_on},{media: ids.btn_menu_anim_off}}>
        </Pressable>

        </View>

        </View>
        <View style={styles.menuButtAcc} dataSet={{ media: ids.menuButtAcc}}>
        <Pressable style={styles.btn_acc} dataSet={{ media: ids.btn_acc}} onPress={() => {Linking.openURL('https://app.skolaria.com')}} >
        <Text numberOfLines={1} selectable = {false} style={styles.text_btn_acc} dataSet={{ media: ids.text_btn_acc}}>
        {t("Accès Skolaria")}</Text>
        </Pressable>

        <Pressable style={styles.btn_free} dataSet={{ media: ids.btn_free}} onPress={() =>
          this.props.stylo.navigate('Pri')
        } >
        <Text selectable = {false} style={styles.text_btn_free} dataSet={{ media: ids.text_btn_free}}>{t("Essai 40 jours")}</Text>
        </Pressable>


        </View>

        </View>

        <View style={styles.vide_bordure}> </View>


        </View>
      )}
      </Consumer>

    );
  }
}
export default withTranslation()(Header)
