import React from "react";
import {Text } from "react-native";

const SeparatorMM = () => {

  return (
    <Text style={
      {
        //  backgroundColor: 'red',
        position: 'fixed',
        flex:1,
        width:'50%',
        left:0,
        marginLeft: '0%',
        borderBottomWidth:'thin',
        borderBottomColor: "darkgrey",
        textAlign : "center",
        alignItems:"center",
        justifyContent:"center"
      }}>
      </Text>

    );
  };



  export default SeparatorMM;
