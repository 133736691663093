import React from "react";
import { FAB } from '@rneui/themed';
import { Consumer } from "./Context"
import Icon from "react-native-vector-icons/dist/MaterialIcons";

import "../icons/icons.js";
import "../icons/styleicons.css";

const Fab_Support = () => {

  return (

    <Consumer>
    {context => (
      <FAB
      visible= {!context.state.openMenu}
      icon={<Icon name="support-agent" style={{color: "white", fontSize:'xxx-large'}} />}
      size="large"
      style={{zIndex: 55555, marginRight: 20, marginBottom: 20}}
      color="#0071e3"
      placement = 'right'
      onPress={() => context.openSup()}
      />

    )}
    </Consumer>

  );
};



export default Fab_Support;
